import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore } from "redux-persist";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";
import thunk from "redux-thunk";

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

const middlewares = [];
const enhancers = [];

/* Saga */

const sagaMiddleware = createSagaMiddleware();

const dev = process.env.NODE_ENV === "development";

//if (dev)
// middlewares.push(thunk);
middlewares.push(sagaMiddleware);
enhancers.push(applyMiddleware(...middlewares));

/* Create Store */

let store = createStore(rootReducer, compose(...enhancers));

/* If developer creates store to use with Redux Dev Tools */
if (dev) {
  store = createStore(rootReducer, composeWithDevTools(...enhancers));
}

export default store;

/* Redux-Persist + Store */

export const persistor = persistStore(store);

/* Run saga */

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

/*
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"

import rootReducer from "./reducers/rootReducer"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./sagas/rootSaga"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch

export default store
*/
