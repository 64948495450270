import { takeEvery, takeLatest, call, put, take } from "redux-saga/effects";

import backend from "../config/axios";
import {
  fetchUserData,
  fetchPreviewHash,
  setPreviewId,
  setPreviewHash,
  setLocation,
  setBankGroups,
  setReady,
  setInProgress,
  setState
} from "../reducers/surveyUserData";

function loadLocation(hash: string) {
  return new Promise<any>(resolve => {
    const loc = backend.post("/Answers/getSurveyLocation", { hash: hash });
    loc
      .then((response: any) => {
        const data = response[0];
        let ready = false;
        if (Number(data.ready) === 1) {
          ready = true;
        }
        resolve({
          step: data.step,
          page: data.page,
          panel: data.panel,
          ready: ready
        });
      })
      .catch(e => {
        resolve({});
      });
  });
}

function loadBankGroup(
  hash: string,
  group: any,
  bankById: (id: number) => any
) {
  const questionId = group.question.kysymys_id;
  return new Promise<any>(resolve => {
    const loc = backend.post("/Answers/getBanks", {
      hash: hash,
      question_id: questionId
    });
    loc
      .then((response: any) => {
        const banks: any[] = [];
        let i: number = 0;
        while (i < group.amount) {
          banks.push({
            id: undefined,
            name: undefined
          });
          i++;
        }
        response.map((b: any) => {
          if (b.value) {
            let bId: number = 0;
            if (Number(b.bank_id) > 0) bId = Number(b.bank_id) - 1;
            banks[bId] = bankById(Number(b.value));
          }
        });
        group.banks = banks;

        resolve(group);
      })
      .catch(e => {
        resolve(group);
      });
  });
}

function* createPreviewHash(data: any) {
  const survey_id: number = data.payload.survey_id;
  const token: string = data.payload.token;
  const hash = yield call(
    () =>
      new Promise<any>(resolve => {
        const hash = backend.post(
          "/Surveys/createPreviewHash",
          {
            survey_id: survey_id
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        hash
          .then((response: any) => {
            window.open("/esikatselu/" + response.hash);
            resolve(response.hash);
          })
          .catch(e => {
            resolve(undefined);
          });
      })
  );
  yield put(setPreviewId(survey_id));
  yield put(setPreviewHash(hash));
}

function* loadUserData(hash: string, data: any) {
  yield put(setLocation({}));

  const location = yield call(() => loadLocation(hash));
  yield put(setLocation(location));

  const bankGroups: any[] = [];

  if (data.groups && data.groups.length > 0) {
    let i: number = 0;
    while (i < data.groups.length) {
      bankGroups.push(
        yield call(() => loadBankGroup(hash, data.groups[i], data.bankById))
      );
      //yield put(setLocation(bankGroups[i]));
      i++;
    }
  }

  yield put(setBankGroups(bankGroups));
}

function* addReady(data: any) {
  const hash = data.payload;

  backend.post("/Answers/setsurveyLocation", {
    ready: 1,
    hash: hash
  });
}

function* removeReady(data: any) {
  const hash = data.payload;

  backend.post("/Answers/setsurveyLocation", {
    ready: 0,
    hash: hash
  });
}

function* checkUserData(data: any) {
  const hash = data.payload.hash;

  yield put(setLocation({}));
  yield put(setBankGroups(undefined));

  const location = yield call(() => loadLocation(hash));
  yield put(setLocation(location));

  const bankGroups: any[] = [];

  if (data.payload.groups && data.payload.groups.length > 0) {
    let i: number = 0;

    while (i < data.payload.groups.length) {
      bankGroups.push(
        yield call(() =>
          loadBankGroup(hash, data.payload.groups[i], data.payload.bankById)
        )
      );
      //yield put(setLocation(bankGroups[i]));
      i++;
    }
  }

  yield put(setBankGroups(bankGroups));
}

function* surveyUserSaga() {
  yield takeEvery(fetchUserData.type, checkUserData);
  yield takeEvery(fetchPreviewHash.type, createPreviewHash);
  yield takeEvery(setReady.type, addReady);
  yield takeEvery(setInProgress.type, removeReady);
}

export default surveyUserSaga;
