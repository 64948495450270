import React, { Suspense, lazy } from "react";
import { AppState } from "../reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { Route, useHistory } from "react-router-dom";

export interface MenuItem {
  title: string;
  path: string;
  component?: string;
  icon?: JSX.Element;
  id?: number;
  sub?: MenuItem[];
  hide?: Boolean;
}

export const PageRoutes: MenuItem[] = [
  {
    title: "Etusivu",
    path: "/",
    component: "Home/index"
  },
  {
    title: "Survey",
    path: "/q/:hashString",
    component: "Home/survey"
  },
  {
    title: "Survey",
    path: "/makePdf/:hashString/:printPdf",
    component: "Home/survey"
  },
  {
    title: "Kirjaudu",
    path: "/kirjaudu",
    component: "User/Login"
  },
  {
    title: "Kirjaudu ulos",
    path: "/kirjaudu-ulos",
    component: "User/Logout"
  }
];

const PageRoutesAdmin: MenuItem[] = [
  {
    title: "Etusivu",
    path: "/",
    component: "Admin/index"
  },
  {
    title: "Esikatselu",
    path: "/esikatselu/:hashString",
    component: "Home/survey"
  },
  {
    title: "Vastaajat",
    path: "/respondents",
    component: "Admin/respondents"
  },
  {
    title: "Tee kopio",
    path: "/copy",
    component: "Admin/copy"
  },
  {
    title: "Kirjaudu",
    path: "/kirjaudu",
    component: "User/Login"
  },
  {
    title: "Kirjaudu ulos",
    path: "/kirjaudu-ulos",
    component: "User/Logout"
  }
];

export const CreateRoutes = () => {
  const user = useSelector((state: AppState) => state.user);

  function lazyLoadComponent(url?: string) {
    // Lazy loading main pages
    return lazy(() => import("./" + url));
  }

  let menuItems: MenuItem[] = [];

  if (user.logged && user.info?.role && user.info?.role == 100) {
    menuItems = PageRoutesAdmin;
  } else {
    menuItems = PageRoutes;
  }

  const menuRoutes = menuItems.map((item: MenuItem, key: number) => {
    if (item.component) {
      /*const route = <Route key={key} exact path={item.path} component={lazyLoadComponent(item.component)} />;
        let subRoutes = null;
        if ( item.sub && item.sub.length > 0 ) {
          subRoutes = item.sub.map( (subItem: MenuItem, key) => {
            if (subItem.path && subItem.component) {
              return <Route key={key} exact path={item.path+subItem.path} component={lazyLoadComponent(subItem.component)} />;
            }
          } ) 
        }
        return route; //<React.Fragment>{route}{subRoutes}</React.Fragment>;*/

      return (
        <Route
          key={key}
          exact
          path={item.path}
          component={lazyLoadComponent(item.component)}
        />
      );
    } else {
      if (item.sub && item.sub.length > 0) {
        const subRoutes = item.sub.map((subItem: MenuItem, key) => {
          if (subItem.path && subItem.component) {
            return (
              <Route
                key={key}
                exact
                path={item.path + subItem.path}
                component={lazyLoadComponent(subItem.component)}
              />
            );
          }
        });
        return subRoutes;
      }
    }
  });

  return <React.Fragment>{menuRoutes}</React.Fragment>;
};

/*
export const MainMenu: React.FC = () => {
  let history = useHistory();
  const role = useSelector((state: AppState) => state.user.info?.role);

  const { SubMenu } = Menu;

  function to(path?: string) {
    if (path) {
      history.push(path);
    } else {
      history.push("/");
    }
  }

  function Item(item: MenuItem, ppath?: string) {
    let path = item.path;
    if (!item.hide) {
      if (ppath) path = ppath + item.path;
      if (item.component) {
        if (item.sub) {
          return (
            <SubMenu title={item.title}>
              <Menu.Item key={item.path} onClick={() => to(path)}>
                {item.title}
              </Menu.Item>
              {item.sub.map((subitem: MenuItem) => {
                return Item(subitem, path);
              })}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={item.path} onClick={() => to(path)}>
            {item.title}
          </Menu.Item>
        );
      } else if (item.sub) {
        return (
          <SubMenu title={item.title}>
            {item.sub.map((subitem: MenuItem) => {
              return Item(subitem, path);
            })}
          </SubMenu>
        );
      } else {
      }
    }
    return "";
  }

  let menuItems: MenuItem[] = PageRoutes;

  if (role) {
    if (role == 100) {
      menuItems = PageRoutesAdmin;
    } else if (role == 1) {
      menuItems = PageRoutesUser;
    }
  }

  return (
    <div className="mb-8">
      <Menu mode="horizontal">
        {menuItems.map((item: MenuItem) => {
          if ( item.id ) {
              //const checker = serviceList.filter( ( service ) => service.id === item.id );
              //if ( checker.length === 0 ) {
              //    return false;
              //}
          }
          return Item(item);
        })}
      </Menu>
    </div>
  );
};
*/
