import { takeEvery, takeLatest, call, put, take } from "redux-saga/effects";
import {
  ResearchList,
  ResearchQuestions,
  Question,
  IPutResearchDetails,
  QuestionGroups,
  QuestionGroupQuestion
} from "../reducers/researchSlice";
import backend from "../config/axios";
import {
  setList,
  fetchList,
  setGroupQuestions,
  fetchGroupQuestions,
  fetchResearch,
  setResearch,
  fetchSurvey,
  setSurvey,
  setResearchQuestions,
  setSurveyPreview,
  setActList,
  setSurveyOpts,
  setSurveyItems,
  reset,
  saveCopyResearch,
  saveCopyResearchSucceeded
} from "../reducers/researchSlice";

function* loadResearchList(data: any) {
  const url = "listResearch";
  try {
    const response = yield call(() =>
      backend.post("/Export/" + url, null, {
        headers: {
          Authorization: `Bearer ${data.payload}`
        }
      })
    );
    const list: ResearchList = {
      list: response,
      status: "success"
    };
    yield put(setList(list));
  } catch (e) {
    // handle errors
    const list: ResearchList = {
      list: [],
      status: "fail"
    };
  }
}

function* loadSurvey(data: any) {}

function* saveSurvey(data: any) {
  try {
    const opts = data.payload.survey.opts;
    const survey = yield call(() =>
      backend.post(
        "/Surveys/add",
        {
          nimi: opts.nimi,
          lomake: opts.lomake,
          selite: opts.selite,
          ehdot: opts.ehdot,
          ohje: opts.ohje,
          steps: data.payload.survey.steps,
          research_id: data.payload.survey.tutkimus_id
        },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
  } catch (e) {}
}

function* loadResearch(data: any) {
  try {
    const survey = yield call(() =>
      backend.post(
        "/Surveys/get",
        { research_id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
    yield put(
      setSurveyOpts({
        surveyProps: {
          nimi: survey.nimi,
          lomake: survey.lomake,
          selite: survey.selite,
          ehdot: { ...survey.ehdot },
          ohje: { ...survey.ohje }
        },
        surveySteps: [...survey.steps],
        id: Number(data.payload.id)
      })
    );
    yield put(setActList(data.payload.id));
  } catch (e) {
    yield put(setActList(data.payload.id));
  }

  const url = "getResearchQuestion";
  try {
    const questions = yield call(() =>
      backend.post(
        "/Export/" + url,
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
    const kyssarit: Question[] = [];
    questions.map((q: any) => {
      if (q.kysymys) {
        const k: Question = q.kysymys;
        kyssarit.push(k);
      }
    });
    const list: ResearchQuestions = {
      questions: kyssarit,
      status: "success"
    };
    const details: IPutResearchDetails = {
      id: data.payload.id,
      questions: list
    };
    yield put(setResearchQuestions(details));
  } catch (e) {
    // handle errors
  }
}

function* loadGroupQuestions(data: any) {
  // groupQuestions
  const url = "listGroupQuestion"; //"getGroupQuestion";
  try {
    const groupQuestions = yield call(() =>
      backend.post("/Export/" + url, null, {
        headers: {
          Authorization: `Bearer ${data.payload}`
        }
      })
    );

    const list: QuestionGroups = {
      groups: groupQuestions,
      status: "success"
    };

    yield put(setGroupQuestions(list));
  } catch (e) {
    // handle errors
  }
}

function* runSaveCopyResearch(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Surveys/kopioi", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`
        }
      })
    );
    yield put(saveCopyResearchSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

/*function* runLogout() {
  yield put(logout());
}*/

function* researchSaga() {
  yield takeEvery(fetchList.type, loadResearchList);
  yield takeEvery(fetchGroupQuestions.type, loadGroupQuestions);
  yield takeEvery(fetchResearch.type, loadResearch);
  yield takeEvery(fetchSurvey.type, loadSurvey);
  yield takeEvery(setSurvey.type, saveSurvey);

  yield takeEvery(saveCopyResearch.type, runSaveCopyResearch);
}

export default researchSaga;
