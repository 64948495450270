import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Question, QuestionGroup } from "./researchSlice";

import { SurveyStep, SurveyPage } from "./researchSlice";

export interface IDraggable {
  id: number;
  // setSelection: (id: number) => void;
  content: JSX.Element;
  row: Question | QuestionGroup | ResearchSurveyPart;
  type: string;
}

export interface ResearchSurveyPart {
  title: string;
  description: string;
}

export interface ResearchSurveyPropDetails extends ResearchSurveyPart {
  use: boolean;
}

export interface ResearchSurveyProps {
  nimi: string;
  lomake: string;
  selite: string;
  ehdot: ResearchSurveyPropDetails;
  ohje: ResearchSurveyPropDetails;
}

export const defaultResearchSurveyProps = {
  nimi: "",
  lomake: "",
  selite: "",
  ehdot: {
    use: false,
    title: "",
    description: ""
  },
  ohje: {
    use: false,
    title: "",
    description: ""
  }
};

export interface IDraggableItem {
  content: Question | QuestionGroup | ResearchSurveyPart;
  type: string;
  // items?: IDraggableItem[];
}

export interface ResearchSurvey {
  opts: ResearchSurveyProps;
  steps: SurveyStep[]; //IDraggableItem[];
  //tutkimus_id?: number;
  id?: number;
}

export interface IPutSurveyProp {
  prop: string;
  data: string | ResearchSurveyPropDetails;
}

export interface IAddSurveyItem {
  index: number;
  item: Question | QuestionGroup | ResearchSurveyPart;
}

function createInitState(): ResearchSurvey {
  return {
    opts: defaultResearchSurveyProps,
    steps: []
  };
}

/*
steps: [
  {
    title: "Varainhoidon yhteistyökumppanit",
    pages: [
      {
        description:
          "10 tärkeintä varainhoidon yhteistyökumppanianne (täysvaltakirjamandaatit ja suorat rahastosijoitukset, ml. pääomarahastot)? Kuinka suuria sijoitukset ovat (tämänhetkinen markkina-arvo)? Harkitsetteko muuttavanne varainhoitajalla olevan sijoituksen kokoa vuoden kuluessa?",
        items: [
          {
            type: "bank_panel",
            defaultTitle: "Varainhoitoyhtiö",
            defaultLabel: "Yhtiön nimi",
            amount: 10,
            items: [
              {
                type: "description",
                text: "Sijoitusten koko (miljoonaa euroa)",
                items: [
                  {
                    type: "question",
                    label: "Täysvaltakirjamandaatti",
                    endAdornment: "M€",
                    content: {
                      kysymys_id: 1,
                      kysymys: "Mandaatti",
                      tunniste: "AB1.1",
                      muoto: "2",
                      min: "1",
                      max: "5",
                      tieto: "1",
                      luokka: "40",
                      paapankkien_maara: "0",
                      selite: "Rykte (arbetssätt, kontinuitet, pålitlighet)",
                      ryhmittely: null,
                      selite_suomi: "Maine"
                    }
                  },*/

const initialState = createInitState();

const surveyState = createSlice({
  name: "surveyPreview",
  initialState,
  reducers: {
    setSurveyProp: (
      state: ResearchSurvey,
      action: PayloadAction<IPutSurveyProp>
    ) => {
      /*const opts = { ...state.opts };
      const keys = Object.keys(opts);
      const key = action.payload.prop;
      const data = action.payload.data;
      if (key in keys) {
        //typeof data === typeof opts[key]
        state.opts = { ...state.opts, [key]: data };
      }*/
    },
    fetchSurvey: (state: ResearchSurvey, action: PayloadAction<string>) => {},
    setSurvey: (
      state: ResearchSurvey,
      action: PayloadAction<ResearchSurvey>
    ) => {
      if (action.payload.opts) state.opts = action.payload.opts;
      if (action.payload.steps) state.steps = action.payload.steps; //IDraggableItem[];
      if (action.payload.id) state.id = action.payload.id;
    },
    clearSurvey: (state: ResearchSurvey) => {
      state.opts = initialState.opts;
      state.steps = initialState.steps; //IDraggableItem[];
      state.id = undefined;
    },
    setSurveyId: (
      state: ResearchSurvey,
      action: PayloadAction<number | undefined>
    ) => {
      state.id = action.payload;
    },
    setSurveySteps: (
      state: ResearchSurvey,
      action: PayloadAction<SurveyStep[]>
    ) => {
      state.steps = action.payload;
    },
    setPreviewSurveyOpts: (
      state: ResearchSurvey,
      action: PayloadAction<ResearchSurveyProps>
    ) => {
      state.opts = action.payload;
    },
    addSurveyItem: (
      state: ResearchSurvey,
      action: PayloadAction<IAddSurveyItem>
    ) => {},
    removeSurveyItem: (
      state: ResearchSurvey,
      action: PayloadAction<number>
    ) => {}
  }
});

export const {
  setSurveyId,
  setSurveyProp,
  setSurveySteps,
  setPreviewSurveyOpts,
  addSurveyItem,
  removeSurveyItem,
  fetchSurvey,
  setSurvey,
  clearSurvey
} = surveyState.actions;

export default surveyState;
