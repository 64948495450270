import { takeEvery, takeLatest, call, put, take } from "redux-saga/effects";

import backend from "../config/axios";
import { fetchSurvey, setSurvey, clearSurvey } from "../reducers/surveySlice";

function* loadSurvey(data: any) {
  try {
    const survey = yield call(() =>
      backend.post("/Answers/getSurvey", { hash: data.payload })
    );
    if (survey) {
      const addSurvey = {
        opts: {
          nimi: survey.nimi,
          lomake: survey.lomake,
          selite: survey.selite,
          ehdot: survey.ehdot,
          ohje: survey.ohje
        },
        steps: survey.steps,
        id: survey.id
      };
      yield put(setSurvey(addSurvey));
    } else {
      yield put(clearSurvey());
      window.location.href = "/";
    }
  } catch (e) {}
  //setSurvey
}

function* surveySaga() {
  yield takeEvery(fetchSurvey.type, loadSurvey);
}

export default surveySaga;
