import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRespondent {
  id: number;
  servey_id: number;
  tunniste: string;
  email: string;
  status_id: number;
}

export interface IPut {
  token: string | undefined;
  id?: number;
}

export interface RespondentState {
  list: Array<IRespondent> | undefined;
  respondent: IRespondent | undefined;
}

const initialState: RespondentState = {
  list: undefined,
  respondent: undefined
};

const respondentState = createSlice({
  name: "respondent",
  initialState,
  reducers: {
    fetchRespondents: (
      state: RespondentState,
      action: PayloadAction<IPut>
    ) => {},
    fetchRespondentsSucceeded: (
      state: RespondentState,
      action: PayloadAction<Array<IRespondent>>
    ) => {
      state.list = action.payload;
    },
    fetchRespondent: (
      state: RespondentState,
      action: PayloadAction<IPut>
    ) => {},
    fetchRespondentSucceeded: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {
      state.respondent = action.payload;
    },
    saveRespondent: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {},
    saveRespondentSucceeded: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {
      state.respondent = undefined;
    },
    deleteRespondent: (
      state: RespondentState,
      action: PayloadAction<IPut>
    ) => {},
    deleteRespondentSucceeded: (
      state: RespondentState,
      action: PayloadAction<Array<IRespondent>>
    ) => {
      state.list = action.payload;
    },
    saveSend: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {},
    saveSendSucceeded: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {
      state.respondent = undefined;
    },
    saveReminder: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {},
    saveReminderSucceeded: (
      state: RespondentState,
      action: PayloadAction<IRespondent>
    ) => {
      state.respondent = undefined;
    }
  }
});

export const {
  fetchRespondents,
  fetchRespondentsSucceeded,
  fetchRespondent,
  fetchRespondentSucceeded,
  saveRespondent,
  saveRespondentSucceeded,
  deleteRespondent,
  deleteRespondentSucceeded,
  saveSend,
  saveSendSucceeded,
  saveReminder,
  saveReminderSucceeded
} = respondentState.actions;

export default respondentState;
