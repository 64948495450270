import { takeEvery, takeLatest, call, put, take } from "redux-saga/effects";

import backend from "../config/axios";
import {
  loginCheck,
  setStatus,
  login,
  startLogout,
  logout,
  UserLogin,
  IUserState,
  IStatusMessage
} from "../reducers/userData";

function* setUserState(userState: IUserState) {}

function loadUserData(token: string) {
  return new Promise<any>((resolve, reject) => {
    const userInfo = backend.post("/users/get", null, {
      headers: { Authorization: `Bearer ${token}` }
    });
    userInfo
      .then(response => {
        const info = response.data.user;
        const userData: IUserState = {
          username: info.username,
          name: info.name,
          id: info.id,
          token: info.token,
          role: info.role
        };
        resolve(userData);
      })
      .catch(e => {
        reject(e);
      });
  });
}

function getToken(loginInfo: UserLogin) {
  return new Promise<any>((resolve, reject) => {
    const userToken = backend.post("/users/login", loginInfo);
    userToken
      .then(response => {
        const token = response.data.token;
        resolve(loadUserData(token));
      })
      .catch(e => {
        reject(e);
      });
  });
}

export function tryLogin(loginInfo: UserLogin) {
  return getToken(loginInfo);
}

function* checkLogin(data: any) {
  const checking: IStatusMessage = {
    type: "loading",
    message: "Checking user login"
  };
  yield put(setStatus(checking));
  const loginInfo: UserLogin = data.payload;
  try {
    const loadingUsr: IStatusMessage = {
      type: "loading",
      message: "Ladataan käyttäjätietoja..."
    };
    yield put(setStatus(loadingUsr));

    const userInfo = yield call(() => getToken(loginInfo));
    yield put(login(userInfo));
    const ready: IStatusMessage = {
      type: "success",
      message: "Olet kirjautuneena sisään"
    };
    yield put(setStatus(ready));
  } catch (e) {
    // handle errors
    let message: IStatusMessage = {
      type: "error",
      message: "Virhe kirjautumisessa. Kokeile uudelleen."
    };
    yield put(setStatus(message));
    return false;
  }
}

function* runLogout() {
  yield put(logout());
}

function* usersSaga() {
  yield takeEvery(loginCheck.type, checkLogin);
  yield takeEvery(startLogout.type, runLogout);
}

export default usersSaga;
