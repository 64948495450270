import { takeEvery, takeLatest, call, put, take } from "redux-saga/effects";

import backend from "../config/axios";
import { IBank, IRBank, IBanks, IBankStatus } from "../reducers/bankSlice";
import { setBanks, fetchBanks } from "../reducers/bankSlice";

function* loadBanks(data: any) {
  yield put(
    setBanks({
      list: [],
      status: {
        type: "loading",
        message: "Ladataan"
      }
    })
  );
  const url = "listBanks";
  try {
    const response = yield call(() =>
      // add hash here data.payload
      backend.post(
        "/Export/" + url,
        {
          //id: 2,
          hash: data.payload
        },
        {}
      )
    );
    const bankList = response.map((r: IRBank) => {
      return {
        id: r.tunniste,
        name: r.nimi
      };
    });
    const banks: IBanks = {
      list: bankList,
      status: {
        type: "success",
        message: "Pankit ladattu"
      }
    };
    yield put(setBanks(banks));
  } catch (e) {
    // handle errors
    const banks: IBanks = {
      list: [],
      status: {
        type: "error",
        message: "Pankkien lataaminen epäonnistui"
      }
    };
  }
}

function* bankSaga() {
  yield takeEvery(fetchBanks.type, loadBanks);
}

export default bankSaga;
