import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface QuestionGroupQuestion {
  ryhma_id: number;
  kysymys_id: number;
  jarjestys: number;
}

export interface QuestionGroup {
  kysymykset: QuestionGroupQuestion[];
  ryhma_id: number;
  ryhmannimi: string;
  selected?: boolean;
  questions?: Question[];
}

export interface QuestionGroups {
  groups: QuestionGroup[];
  time?: number;
  status?: "loading" | "success" | "fail";
}

export interface Question {
  kysymys: string;
  kysymys_id: number;
  luokka?: number;
  max?: number;
  min?: number;
  muoto?: number;
  paapankkien_maara?: number;
  // ryhmittely
  selite?: string;
  selite_suomi?: string;
  tieto?: number;
  monivalinta?: any;
  tunniste: string;
  selected?: boolean;
}

export interface PanelContent {
  banks?: number;
  title?: string;
  items?: SurveyItem[];
}

export interface ResearchQuestions {
  //id: number;
  //loading: boolean;
  questions: Question[];
  time?: number;
  status?: "loading" | "success" | "fail";
}

export interface ResearchSurveyPart {
  title: string;
  description: string;
}

export interface ResearchSurveyPropDetails extends ResearchSurveyPart {
  use: boolean;
}

export interface ResearchSurveyProps {
  nimi: string;
  lomake: string;
  selite: string;
  ehdot: ResearchSurveyPropDetails;
  ohje: ResearchSurveyPropDetails;
}

export const defaultResearchSurveyProps = {
  nimi: "",
  lomake: "",
  selite: "",
  ehdot: {
    use: false,
    title: "",
    description: ""
  },
  ohje: {
    use: false,
    title: "",
    description: ""
  }
};

export interface IDraggableItem {
  content: Question | QuestionGroup | ResearchSurveyPart;
  type: string;
  index?: number[];
  items?: IDraggableItem[];
}

export interface SurveyItem {
  type: "bank_panel" | "panel" | "question" | "description" | "question_group";
  defaultTitle?: string;
  defaultLabel?: string;
  text?: string;
  label?: string;
  title?: string;
  endAdornment?: string;
  amount?: number;
  content?: Question | QuestionGroup | PanelContent;
  items?: SurveyItem[];
}

export interface SurveyPage {
  description: string;
  items: SurveyItem[];
}

export interface SurveyStep {
  title: string;
  pages: SurveyPage[];
}

export interface ResearchSurvey {
  opts: ResearchSurveyProps;
  // items: IDraggableItem[];
  steps: any[]; //SurveyStep[];
  tutkimus_id?: number;
}

export interface Research {
  club: number;
  kohde: number;
  nimi: string;
  pankki_maara: number;
  patevyys: number;
  poistettu: number;
  tutkimus_id: number;
  vastaus_maara: number;
  vuosi: number;
  created: number;
  kysymykset?: ResearchQuestions;
  kysymysryhmat?: QuestionGroups;
  survey?: ResearchSurvey;
  //loading?: boolean;
}

export interface IPutResearchDetails {
  id: number;
  questions?: ResearchQuestions;
  groupQuestions?: QuestionGroups;
  surveyProps?: ResearchSurveyProps;
  surveyItems?: IDraggableItem[];
  surveySteps?: any[];
  status?: "loading" | "success" | "fail";
}

export interface ResearchList {
  list: Research[];
  time?: number;
  status?: "loading" | "success" | "fail";
  activeList?: number | undefined;
  surveyPreview?: undefined | ResearchSurvey;
  groupQuestions?: QuestionGroups;
}

export interface ICopy {
  id: number;
  token: string;
  copy_survey_id: number;
}

export interface ITokenId {
  token: string | undefined;
  id?: number;
}

export interface ITokenSurvey {
  token: string | undefined;
  survey: ResearchSurvey;
}

function createInitState(): ResearchList {
  return {
    list: []
  };
}

const initialState = createInitState();

const researchState = createSlice({
  name: "researchList",
  initialState,
  reducers: {
    saveCopyResearch: (state: ResearchList, action: PayloadAction<ICopy>) => {},
    saveCopyResearchSucceeded: (
      state: ResearchList,
      action: PayloadAction<ICopy>
    ) => {
      window.location.href = "/";
    },

    setList: (state: ResearchList, action: PayloadAction<ResearchList>) => {
      state.list = action.payload.list;
      state.status = action.payload.status;
    },
    fetchList: (
      state: ResearchList,
      action: PayloadAction<String | undefined>
    ) => {},
    setGroupQuestions: (
      state: ResearchList,
      action: PayloadAction<QuestionGroups>
    ) => {
      state.groupQuestions = action.payload;
    },
    fetchGroupQuestions: (
      state: ResearchList,
      action: PayloadAction<String | undefined>
    ) => {},
    setResearch: (state: ResearchList, action: PayloadAction<Research>) => {
      //
    },
    setResearchQuestions: (
      state: ResearchList,
      action: PayloadAction<IPutResearchDetails>
    ) => {
      const list = state.list.map((research: Research) => {
        if (action.payload.id && research.tutkimus_id === action.payload.id) {
          if (action.payload.questions) {
            research.kysymykset = action.payload.questions;
          } else if (action.payload.groupQuestions) {
            research.kysymysryhmat = action.payload.groupQuestions;
          }
          return research;
        }
        return research;
      });
      state.list = list;
    },
    fetchResearch: (state: ResearchList, action: PayloadAction<ITokenId>) => {},
    setSurveyPreview: (
      state: ResearchList,
      action: PayloadAction<ResearchSurvey | undefined>
    ) => {},
    fetchSurvey: (state: ResearchList, action: PayloadAction<ITokenId>) => {},
    setSurvey: (state: ResearchList, action: PayloadAction<ITokenSurvey>) => {},
    setActList: (
      state: ResearchList,
      action: PayloadAction<number | undefined>
    ) => {
      state.activeList = action.payload;
    },
    setSurveyOpts: (
      state: ResearchList,
      action: PayloadAction<IPutResearchDetails>
    ) => {
      const list = state.list.map((research: Research) => {
        if (
          action.payload.id &&
          Number(research.tutkimus_id) === Number(action.payload.id)
        ) {
          if (!research.survey) {
            research.survey = {
              opts: defaultResearchSurveyProps,
              // items: [],
              steps: [],
              tutkimus_id: action.payload.id
            };
          }

          if (action.payload.surveySteps || action.payload.surveyProps) {
            const survey = { ...research.survey };

            if (action.payload.surveyProps) {
              survey.opts = action.payload.surveyProps;
            }
            if (action.payload.surveySteps) {
              const surveySteps = action.payload.surveySteps.map(step => {
                const rStep = { ...step };
                const pages = step.pages.map((page: any) => {
                  const rPage = { ...page };
                  if (page.items) {
                    const items = page.items.map((item: any) => {
                      const rItem = { ...item };
                      if (item.items) {
                        const rItemItems = item.items.map((itemItem: any) => {
                          return { ...itemItem };
                        });
                        rItem.items = [...rItemItems];
                      }
                      return { ...rItem };
                    });
                    rPage.items = [...items];
                  }
                  return { ...rPage };
                });
                rStep.pages = [...pages];
                return rStep;
              });
              survey.steps = surveySteps;
              //survey.steps = action.payload.surveySteps;
            }
            research.survey = { ...survey };
          }

          return research;
        }
        return research;
      });
      state.list = list;
    },
    setSurveyItems: (
      state: ResearchList,
      action: PayloadAction<IPutResearchDetails>
    ) => {},
    reset: (state: ResearchList) => {
      state = initialState;
    }
  }
});

export const {
  setList,
  fetchList,
  setGroupQuestions,
  fetchGroupQuestions,
  setResearch,
  setResearchQuestions,
  fetchResearch,
  setSurveyPreview,
  fetchSurvey,
  setSurvey,
  setActList,
  setSurveyOpts,
  setSurveyItems,
  reset,
  saveCopyResearch,
  saveCopyResearchSucceeded
} = researchState.actions;

export default researchState;
