import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBank {
  id: number;
  name: string;
}

export interface IRBank {
  luokka: number;
  nimi: string;
  pankki_id: number;
  tunniste: number;
}

export interface ISurveyBank {
  id: number | undefined;
  name: string | undefined;
}

export interface IBankStatus {
  type?: "loading" | "success" | "info" | "warning" | "error";
  message: string;
  description?: string;
  time?: number;
}

export interface IBanks {
  list: IBank[];
  status?: IBankStatus;
  time?: number;
}

function createInitState(): IBanks {
  return {
    list: []
  };
}

const initialState = createInitState();

const bankState = createSlice({
  name: "banks",
  initialState,
  reducers: {
    fetchBanks: (state: IBanks, action: PayloadAction<string>) => {},
    setBanks: (state: IBanks, action: PayloadAction<IBanks>) => {
      state.list = action.payload.list;
      state.status = action.payload.status;
    },
    resetBanks: (state: IBanks) => {
      state = initialState;
    }
  }
});

export const { fetchBanks, setBanks, resetBanks } = bankState.actions;

export default bankState;
