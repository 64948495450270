import { all, takeEvery } from "redux-saga/effects";
import { PayloadActionWithResolve } from "../reducers/rootReducer";
import userSaga from "./userSaga";
import researchSaga from "./researchSaga";
import bankSaga from "./bankSaga";
import surveyUserSaga from "./surveyUserSaga";
import respondentSaga from "./respondentSaga";
import surveySaga from "./surveySaga";
export default function* rootSaga() {
  // Attach an "onResolve" method on any
  // payload dispatched by a Saga so it can be
  // called here and you get feedback from within your component

  yield takeEvery(
    "*",
    (
      action: PayloadActionWithResolve<any> & {
        "@@redux-saga/SAGA_ACTION": boolean;
      }
    ) => {
      if (action.onResolve && action["@@redux-saga/SAGA_ACTION"]) {
        action.onResolve(action.payload);
      }
    }
  );
  yield all([
    userSaga(),
    researchSaga(),
    bankSaga(),
    surveyUserSaga(),
    respondentSaga(),
    surveySaga()
  ]);
}
