import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { ISurveyBank } from "./bankSlice";
import { Question } from "./researchSlice";

export interface IBankGroup {
  amount: number;
  question: Question | undefined;
  tunniste: string;
  banks: ISurveyBank[] | undefined;
}

export interface ISurveyLocation {
  step?: number;
  page?: number;
  panel?: number;
  ready?: boolean;
}

export interface ISurveyUser {
  location: ISurveyLocation | undefined;
  bankGroups: IBankGroup[] | undefined;
  ready: boolean;
  hash?: string;
  id?: number;
  hiddentext?: boolean;
}

export interface Answer {
  question_id: number;
  value: string | number | null;
  bank_id: number;
}

export interface ILoadSurveyUser {
  hash: string;
  question_id: number[];
}

export interface ISurveyInfo {
  hash: string | undefined;
  groups: IBankGroup[];
  bankById: (id: number) => any;
}

interface IHashProps {
  survey_id: number;
  token: string;
}

function createInitState(): ISurveyUser {
  return {
    location: undefined,
    bankGroups: undefined,
    ready: false,
    hiddentext: false
  };
}

const initialState = createInitState();

const surveyUserState = createSlice({
  name: "surveyUser",
  initialState,
  reducers: {
    fetchPreviewHash: (
      state: ISurveyUser,
      action: PayloadAction<IHashProps>
    ) => {},
    fetchUserData: (
      state: ISurveyUser,
      action: PayloadAction<ISurveyInfo>
    ) => {},
    setPreviewHash: (
      state: ISurveyUser,
      action: PayloadAction<string | undefined>
    ) => {
      state.hash = action.payload;
    },
    setHiddenTextTrue: (state: ISurveyUser, action: PayloadAction<string>) => {
      state.hiddentext = true;
      console.log("Laitetaan true");
    },
    setHiddenTextFalse: (state: ISurveyUser, action: PayloadAction<string>) => {
      state.hiddentext = false;
      console.log("Laitetaan false");
    },
    setReady: (state: ISurveyUser, action: PayloadAction<string>) => {
      state.ready = true;
    },
    setInProgress: (state: ISurveyUser, action: PayloadAction<string>) => {
      state.ready = false;
      state.location = undefined;
    },
    setState: (state: ISurveyUser, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setPreviewId: (
      state: ISurveyUser,
      action: PayloadAction<number | undefined>
    ) => {
      state.id = action.payload;
    },
    setLocation: (
      state: ISurveyUser,
      action: PayloadAction<ISurveyLocation | undefined>
    ) => {
      state.location = action.payload;
      if (action.payload?.ready) state.ready = action.payload.ready;
    },
    setBankGroups: (
      state: ISurveyUser,
      action: PayloadAction<IBankGroup[] | undefined>
    ) => {
      state.bankGroups = action.payload;
    }
  }
});

export const {
  // startLogout,
  fetchUserData,
  setLocation,
  setBankGroups,
  fetchPreviewHash,
  setPreviewHash,
  setPreviewId,
  setReady,
  setInProgress,
  setState,
  setHiddenTextTrue,
  setHiddenTextFalse
} = surveyUserState.actions;

export default surveyUserState;
