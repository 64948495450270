import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";

export interface IUserState {
  id: number;
  username: string;
  name: string;
  role: number;
  token: string;
}

export interface ServiceAccess {
  id: number;
  value: number;
}

export interface IStatusMessage {
  type?: "loading" | "success" | "info" | "warning" | "error";
  message: string;
  description?: string;
  time?: number;
}

export interface IUser {
  logged: boolean;
  info?: IUserState;
  service_access?: ServiceAccess[];
  status?: IStatusMessage;
}

export interface UserLogin {
  username: string;
  password: string;
}

/*const initialState: IUser = {
  logged: false,
  info: undefined,
  service_access: undefined,
  status: undefined
};*/

function createInitState(): IUser {
  return {
    logged: false,
    info: undefined,
    service_access: undefined,
    status: undefined
  };
}

const initialState = createInitState();

const userState = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginCheck: (state: IUser, action: PayloadAction<UserLogin>) => {},
    setStatus: (state: IUser, action: PayloadAction<IStatusMessage>) => {
      state.status = action.payload;
    },
    login: (state: IUser, action: PayloadAction<IUserState>) => {
      state.logged = true;
      state.info = action.payload;
    },
    startLogout: (state: IUser) => {},
    logout: (state: IUser) => {
      state.logged = initialState.logged;
      state.info = initialState.info;
      state.service_access = initialState.service_access;
      state.status = initialState.status;
    }
  }
});

export const {
  startLogout,
  loginCheck,
  setStatus,
  login,
  logout
} = userState.actions;

export default userState;
