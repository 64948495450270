import React, { Suspense, lazy } from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { styleSettings } from "./config/materialStyles";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CreateRoutes, MenuItem } from "./pages/PageRouter";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden"
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  }
}));

/*
const PageRoute = (item: MenuItem, key: string) => {
  function lazyLoadComponent(url: string) {
    // Lazy loading main pages
    console.log('url: '+url);
    return lazy(() => import("./pages/" + url));
  }

  let subRoutes = null;
  let route = null;
  if (item.sub && item.sub.length > 0) {
    subRoutes = item.sub.map((subItem: MenuItem, subkey) => {
      return PageRoute(subItem, key + "-" + subkey); //(subkey + 1) / 100)
    });
  }

  if (item.path && item.component) {
    route = (
      <Route
        key={key}
        exact
        path={item.path}
        component={lazyLoadComponent(item.component)}
      />
    );
  }

  return (
    <React.Fragment>
      {route ? route : ""}
      {subRoutes ? subRoutes : ""}
    </React.Fragment>
  );
};*/

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <Router>
      <Suspense fallback={<div className="text-center pt-12">Ladataan...</div>}>
        <Switch>
          <CreateRoutes />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default styleSettings(App);
