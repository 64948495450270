import userData from "./userData";
import researchSlice from "./researchSlice";
import bankSlice from "./bankSlice";
import surveySlice from "./surveySlice";
import surveyUserData from "./surveyUserData";
import respondentSlice from "./respondentSlice";

import { PayloadAction } from "@reduxjs/toolkit";

import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
//import * as localforage from "localforage";

export type PayloadActionWithResolve<T> = {
  onResolve?: (data?: any) => void;
} & PayloadAction<T>;

const rootReducer = persistCombineReducers(
  {
    key: "root",
    storage: storage,
    //whitelist
    blacklist: ["researchList", "respondent"] //, "surveyUser"
  },
  {
    user: userData.reducer,
    researchList: researchSlice.reducer,
    banks: bankSlice.reducer,
    survey: surveySlice.reducer,
    surveyUser: surveyUserData.reducer,
    respondent: respondentSlice.reducer
  }
);

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
