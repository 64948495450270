import { CssBaseline } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";

export const palette = {
  primary: {
    light: "#5A81CA",
    main: "#014587",
    dark: "#01498C",
    contrastText: "#fff"
  },
  secondary: {
    light: "#22598C",
    main: "#0E3A64",
    dark: "#0B2D4C",
    contrastText: "#fff"
  }
};

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  typography: {
    fontFamily: "Lato, sans-serif",
    h1: {
      fontFamily: "Lora, serif",
      fontSize: "1.7rem",
      fontWeight: 400
      /*textTransform: 'uppercase',*/
      /*'@media (min-width:600px)': {
				fontSize: '1.5rem',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '2.4rem',
			},*/
    },
    h2: {
      fontFamily: "Lora, serif",
      fontSize: "1.55rem",
      fontWeight: 400
    },
    h3: {
      fontFamily: "Lora, serif",
      fontSize: "1.4rem",
      fontWeight: 400
    },
    h4: {
      fontFamily: "Lora, serif",
      fontSize: "1.25rem",
      fontWeight: 400
    },
    h5: {
      fontFamily: "Lora, serif",
      fontSize: "1.1rem",
      fontWeight: 400
    },
    h6: {
      /*fontFamily: "Lora, serif",*/
      fontSize: "1.1rem",
      fontWeight: 400
    },
    body1: {
      //fontSize: "0.95rem",
      fontSize: "0.95rem",
      lineHeight: "1.5em",
      //lineHeight: "1.4rem",
      marginTop: "0.3rem",
      marginBottom: "0.4rem"
    },
    caption: {
      //lineHeight: '1.3rem',
    }
    /*button: {
            lineHeight: '1.25rem',
            verticalAlign: 'text-bottom',
            paddingBottom: '0.15rem !important',
        },*/
  },
  palette: palette,
  overrides: {
    MuiInput: {
      input: {
        "&[type=number]": {
          fontSize: "1.9rem"
        }
      }
    }

    /*MuiFormLabel: {
      root: {
        letterSpacing: "4rem"
      }
    }*/
  }
  /*{
		primary: {
			light: "#5A81CA",
			main: "#014587",
			dark: "#01498C",
			contrastText: "#fff",
		},
		secondary: {
			light: "#22598C",
			main: "#0E3A64",
			dark: "#0B2D4C",
			contrastText: "#fff",
		},
	},*/
});

export function styleSettings(Component: any) {
  function StyleSettings(props: object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return StyleSettings;
}
