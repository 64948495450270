import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchRespondents,
  fetchRespondentsSucceeded,
  fetchRespondent,
  fetchRespondentSucceeded,
  saveRespondent,
  saveRespondentSucceeded,
  deleteRespondent,
  deleteRespondentSucceeded,
  saveSend,
  saveSendSucceeded,
  saveReminder,
  saveReminderSucceeded,
  IPut
} from "../reducers/respondentSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchRespondents(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Surveys/listHash",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
    yield put(fetchRespondentsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchRespondent(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Surveys/listHash",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
    yield put(fetchRespondentSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveRespondent(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Surveys/addHash", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`
        }
      })
    );
    yield runFetchRespondents(data);
    yield put(saveRespondentSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runDeleteRespondent(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Surveys/deleteHash",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`
          }
        }
      )
    );
    yield put(deleteRespondentSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveSend(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Surveys/sendHasHEmail", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`
        }
      })
    );
    yield runFetchRespondents(data);
    yield put(saveSendSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveReminder(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Surveys/sendReminderEmail", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`
        }
      })
    );
    yield runFetchRespondents(data);
    yield put(saveReminderSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* respondentSaga() {
  yield takeEvery(fetchRespondents.type, runFetchRespondents);
  yield takeEvery(fetchRespondent.type, runFetchRespondent);
  yield takeEvery(saveRespondent.type, runSaveRespondent);
  yield takeEvery(deleteRespondent.type, runDeleteRespondent);
  yield takeEvery(saveSend.type, runSaveSend);
  yield takeEvery(saveReminder.type, runSaveReminder);
}

export default respondentSaga;
